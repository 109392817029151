import Icon from '../Icon'
import { FieldQtyButton, FieldQtyInput, FieldQtyWrapper } from './FieldQty.styles'

const FieldQty = ({ value, onChange }) => {
  const add = () => {
    onChange(value + 1)
  }

  const remove = () => {
    onChange(value - 1)
  }

  return (
    <FieldQtyWrapper>
      <FieldQtyButton onClick={remove}>
        <Icon name="minus" />
      </FieldQtyButton>
      <FieldQtyInput readOnly value={value} />
      <FieldQtyButton onClick={add}>
        <Icon name="plus" />
      </FieldQtyButton>
    </FieldQtyWrapper>
  )
}

FieldQty.propTypes = {}

export default FieldQty
