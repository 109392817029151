import { useState } from 'react'

import { CURRENCY_SIGN } from '@/lib/helpers'

import { SummaryItemDetails, SummaryItemImage, SummaryItemPrice, SummaryItemWrapper } from './Summary.styles'

const SummaryItem = ({ product }) => {
  const { id, imageUrl, subtitle, title, price, qty } = product
  const [showRemoveModal, setShowRemoveModal] = useState(false)

  return (
    <>
      <SummaryItemWrapper key={id}>
        <SummaryItemImage>
          <img src={imageUrl} alt="" />
        </SummaryItemImage>
        <SummaryItemDetails>
          <h6>{subtitle}</h6>
          <h2>{title}</h2>
        </SummaryItemDetails>
        <SummaryItemPrice>
          <span>{qty}x</span>
          {CURRENCY_SIGN}
          {price.toFixed(2)}
        </SummaryItemPrice>
      </SummaryItemWrapper>
    </>
  )
}

export default SummaryItem
