import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import useLayout from '@/layout/useLayout'
import { CURRENCY_SIGN } from '@/lib/helpers'

import useCheckout from '../../hooks/useCheckout'
import Step from '../Step/Step'
import { PaymentFooter, PaymentOptions, PaymentTotal } from './Payment.styles'
import PaymentOption from './PaymentOption'

const Payment = () => {
  const { t } = useTranslation()
  const { cartTotal, paymentMethod, setPaymentMethod, setCurrentStep, setPaymentError } = useCheckout()
  const { setShowBack } = useLayout()

  const handlePayment = () => {
    if (paymentMethod === 'CARD') {
      try {
        window.BtDevice.startPayment(cartTotal)
      } catch (error) {
        console.error('[DEVICE] startPayment error', error)
      }
    } else {
      setCurrentStep('CONFIRMATION')
    }
  }

  const methods = [
    {
      icon: 'credit-card',
      name: 'CARD',
      title: t('creditCard')
    },
    {
      icon: 'cash',
      name: 'CASH',
      title: t('cash')
    }
  ]

  useEffect(() => {
    if (window.BtApp) {
      window.BtApp.paymentFinished = (code, msg) => {
        console.log('[APP] paymentFinished', code, msg)
        if (Number(code) !== 0) {
          setPaymentError(msg)
        }
        setCurrentStep('CONFIRMATION')
      }
    }

    setShowBack(true)

    return () => {
      setShowBack(false)
    }
  }, [])

  return (
    <Step>
      <PaymentTotal>
        <dl>
          <dt>{t('totalToPay')}</dt>
          <dd>
            {CURRENCY_SIGN}
            {cartTotal.toFixed(2)}
          </dd>
        </dl>
      </PaymentTotal>
      <PaymentOptions>
        {methods.map(({ icon, name, title }) => (
          <PaymentOption key={name} onClick={() => setPaymentMethod(name)} icon={icon} active={paymentMethod === name}>
            {title}
          </PaymentOption>
        ))}
      </PaymentOptions>
      <PaymentFooter>
        <Button block={true} onClick={() => handlePayment()}>
          {t('payNow')}
        </Button>
      </PaymentFooter>
    </Step>
  )
}

export default Payment
