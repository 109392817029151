import styled, { css, keyframes } from 'styled-components'

const barCodeAnimation = keyframes`
  0% { top: 0; opacity: 0; }
  30% { top: 0; opacity: 0; }
  50% {  opacity: 0.4; }
  70% { top: 100%; opacity: 0; }
  100% { top: 100%; opacity: 0; }
`

export const ScannerIndicatorIcon = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 7rem;
  margin-bottom: 2rem;

  &:after {
    position: absolute;
    top: 0;
    height: 2px;
    left: 0;
    right: 0;
    content: ' ';
    background-color: currentColor;
    animation: ${barCodeAnimation} infinite 3s;
  }

  svg {
    display: block;
    width: 80%;
    height: 80%;
  }
`

export const ScannerIndicatorWrapper = styled.div`
  text-align: center;
  position: relative;
  h5 {
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
  }

  ${props =>
    props.size === 'small' &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      h5 {
        font-size: 0.55rem;
      }

      ${ScannerIndicatorIcon} {
        margin-bottom: 0;
        width: 2.5rem;
        height: 2rem;
        margin-right: 0.5rem;

        &:after {
          height: 1px;
        }
      }
    `};
`

export const ScannerFooter = styled.div`
  margin-top: auto;
  width: 100%;
`

export const ScannerWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ScannerIndicatorWrapper} {
    margin-top: auto;
    margin-bottom: 4rem;
  }
`
