import { useTranslation } from 'react-i18next'

import Modal from '@/components/Modal'

const ProductSizesModal = props => {
  const { product, onClose } = props
  const { stock, imageUrl } = product
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose}>
      <img src={imageUrl} alt="" />
      <h4>{t('availability')}</h4>
      <table>
        <tr>
          <th>{t('size')}</th>
          <th>{t('qty')}</th>
        </tr>
        {stock?.map((size, index) => (
          <tr key={index}>
            <td>{size.name}</td>
            <td>{size.count}</td>
          </tr>
        ))}
      </table>
    </Modal>
  )
}

export default ProductSizesModal
