import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

const withScanner = Component => {
  const WithScannerComponent = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      if (window.BtApp) {
        window.BtApp.handleQrReader = payload => {
          console.log('[SCANNER] Bar Code Scanned', payload)
          navigate(`/product/${payload}`, { replace: location.pathname.includes('/product/') })

          return false
        }
      }
      return () => {
        if (window.BtApp) {
          window.BtApp.handleQrReader = () => {}
        }
      }
    }, [])
    return <Component>{children}</Component>
  }

  return WithScannerComponent
}

export default withScanner
