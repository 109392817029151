import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Button from '@/components/Button'
import products from '@/data/products.json'
import View from '@/layout/View'
import { CURRENCY_SIGN } from '@/lib/helpers'

import useCheckout from '../Checkout/hooks/useCheckout'
import ScannerIndicator from '../Scanner/ScannerIndicator'
import withScanner from '../Scanner/withScanner'
import { ProductContent, ProductDetails, ProductEmpty, ProductImage, ProductPrice, ProductWrapper } from './Product.styles'
import ProductSizesModal from './ProductSizesModal'

const Product = () => {
  const [product, setProduct] = useState({})
  const { id } = useParams()
  const { t } = useTranslation()
  const { addToCart } = useCheckout()
  const navigate = useNavigate()
  const [showStock, setShowStock] = useState(false)

  const handleAddToCart = () => {
    addToCart(product)
    navigate('/checkout')
  }

  useEffect(() => {
    setProduct(products.find(o => o.id === id))
  }, [id])

  return (
    <View theme="LIGHT" middle={true} showBack>
      {product && (
        <ProductWrapper>
          <ProductImage>
            <img src={product.imageUrl} alt="" />
          </ProductImage>
          <ProductContent>
            <h6>{product.subtitle}</h6>
            <h2>{product.title}</h2>
            <ProductDetails>
              {product.details?.map((o, i) => (
                <li key={i}>
                  <dl>
                    <dt>{o.name}</dt>
                    <dd>{o.value}</dd>
                  </dl>
                </li>
              ))}
              {product.stock && (
                <li>
                  <Button onClick={() => setShowStock(true)} rounded={true}>
                    {t('availability')}
                  </Button>
                </li>
              )}
            </ProductDetails>
            <ProductPrice>
              {CURRENCY_SIGN}
              {product.price?.toFixed(2)}
            </ProductPrice>
          </ProductContent>
          <Button block variant="secondary" onClick={() => navigate(`/related/${id}`)}>
            {t('relatedProducts')}
          </Button>
          <Button block variant="primary" onClick={handleAddToCart}>
            {t('addToCart')}
          </Button>
          <ScannerIndicator size="small" text={t('orScanAnotherProduct')} />
          <AnimatePresence>{showStock && <ProductSizesModal product={product} onClose={() => setShowStock(false)} />}</AnimatePresence>
        </ProductWrapper>
      )}
      {!product && <ProductEmpty>{t('cantFindProduct')}</ProductEmpty>}
    </View>
  )
}

export default withScanner(Product)
