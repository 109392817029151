import { AnimatePresence } from 'framer-motion'
import { Route, Routes, useLocation } from 'react-router'
import styled, { css } from 'styled-components'

import Checkout from './features/Checkout'
import Product from './features/Product'
import Related from './features/Related/Related'
import Scanner from './features/Scanner'
import NavBar from './layout/NavBar'
import TopBar from './layout/TopBar'
import useLayout from './layout/useLayout'
import { Cover, TransitionSecondary } from './lib/mixins'

const AppWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const AppHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--size-top-bar) + var(--size-nav-bar));
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px);

  &:before {
    ${Cover()}
    background-color: var(--color-global);
    transform: scaleY(0);
    visibility: hidden;
    transform-origin: center top;
    backface-visibility: hidden;
    ${TransitionSecondary('transform, visibility')}
  }

  ${props =>
    props.theme === 'DARK' &&
    css`
      color: var(--color-inverted);

      &:before {
        transform: scaleY(1);
        visibility: visible;
      }
    `};
`

const AppContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  bottom: 0;
`

const App = () => {
  const location = useLocation()
  const { theme } = useLayout()

  return (
    <AppWrapper>
      <AppHeader theme={theme}>
        <TopBar />
        <NavBar />
      </AppHeader>
      <AppContent>
        <AnimatePresence mode="wait">
          <Routes location={location}>
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/related/:id" element={<Related />} />
            <Route path="/" element={<Scanner />} />
          </Routes>
        </AnimatePresence>
      </AppContent>
    </AppWrapper>
  )
}

export default App
