import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'

import useCheckout from '../../hooks/useCheckout'
import Step from '../Step/Step'
import { ConfirmationContent, ConfirmationFooter } from './Confirmation.styles'

const Confirmation = () => {
  const { t } = useTranslation()
  const { cart, reset, paymentError } = useCheckout()
  const navigate = useNavigate()

  const resetCheckout = () => {
    reset()
    navigate('/')
  }

  const printConfirmation = () => {
    try {
      const data = cart.map(o => ({ name: o.title, amount: o.price * o.qty }))
      window.BtDevice.printInvoice(JSON.stringify(data))
    } catch (error) {
      console.error('[DEVICE] printInvoice error', error)
    }
  }

  return (
    <Step>
      <ConfirmationContent>
        {/* Success */}
        {!paymentError && (
          <>
            <svg viewBox="0 0 188 142">
              <motion.polyline
                stroke="#26C18A"
                strokeWidth="11"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                  delay: 0.25,
                  duration: 0.75
                }}
                fill="none"
                points="4 84.8650185 53.2085289 134.073547 183.282076 4"
              ></motion.polyline>
            </svg>
            <h1>{t('paymentConfirmationTitle')}</h1>
            <p>{t('paymentConfirmationDescription')}</p>
          </>
        )}
        {/* Error */}
        {paymentError && (
          <>
            <svg width="139px" height="138px">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(4.208529, 4.000000)" stroke="#E43232" strokeWidth="11">
                  <motion.line
                    x1="0"
                    y1="130.073547"
                    x2="130.073547"
                    y2="0"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                      delay: 0.25,
                      duration: 0.75
                    }}
                  ></motion.line>
                  <motion.line
                    x1="0"
                    y1="130.073547"
                    x2="130.073547"
                    y2="0"
                    transform="translate(65.036774, 65.036774) scale(-1, 1) translate(-65.036774, -65.036774) "
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                      delay: 0.75,
                      duration: 0.75
                    }}
                  ></motion.line>
                </g>
              </g>
            </svg>
            <h1>{t('paymentErrorTitle')}</h1>
            <p>{paymentError}</p>
          </>
        )}
      </ConfirmationContent>
      <ConfirmationFooter>
        {!paymentError && (
          <Button block={true} variant="secondary" onClick={() => printConfirmation()}>
            {t('printConfirmation')}
          </Button>
        )}
        <Button block={true} onClick={() => resetCheckout()}>
          {t('close')}
        </Button>
      </ConfirmationFooter>
    </Step>
  )
}

export default Confirmation
