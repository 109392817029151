import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'

import { PaymentOptionContent, PaymentOptionIcon, PaymentOptionWrapper } from './Payment.styles'

const PaymentOption = ({ children, icon, logotypes, active, onClick }) => {
  const { t } = useTranslation()
  const startPayment = () => {}

  return (
    <PaymentOptionWrapper active={active} onClick={onClick}>
      <PaymentOptionIcon>
        <Icon name={icon} />
      </PaymentOptionIcon>
      <PaymentOptionContent>
        <h2>{children}</h2>
      </PaymentOptionContent>
    </PaymentOptionWrapper>
  )
}

export default PaymentOption
