import { shuffle } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import products from '@/data/products.json'
import View from '@/layout/View'
import { CURRENCY_SIGN } from '@/lib/helpers'

import ScannerIndicator from '../Scanner/ScannerIndicator'
import withScanner from '../Scanner/withScanner'
import { RelatedBody, RelatedHeader, RelatedItem, RelatedScanner, RelatedWrapper } from './Related.styles'

const Related = () => {
  const [product, setProduct] = useState({})
  const { id } = useParams()
  const { t } = useTranslation()
  const [relatedProducts] = useState(shuffle(products.filter(o => o.id !== id)).slice(0, 6))

  useEffect(() => {
    setProduct(products.find(o => o.id === id))
  }, [id])

  return (
    <View theme="LIGHT" showBack>
      {product && (
        <RelatedWrapper>
          <RelatedHeader>
            <img src={product.imageUrl} alt="" />
            <h4>{t('relatedProducts')}</h4>
          </RelatedHeader>
          <RelatedBody>
            {relatedProducts.map(product => (
              <RelatedItem key={product.id}>
                <img src={product.imageUrl} alt="" />
                <h6>{product.subtitle}</h6>
                <h2>{product.title}</h2>
                <h3>
                  {CURRENCY_SIGN}
                  {product.price.toFixed(2)}
                </h3>
              </RelatedItem>
            ))}
          </RelatedBody>
          <RelatedScanner>
            <ScannerIndicator size="small" />
          </RelatedScanner>
        </RelatedWrapper>
      )}
    </View>
  )
}

export default withScanner(Related)
