import styled from 'styled-components'

import { Reset } from '@/lib/mixins'

export const FieldQtyWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const FieldQtyButton = styled.button`
  ${Reset('button')}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;

  svg {
    width: 50%;
    display: block;
  }
`

export const FieldQtyInput = styled.input`
  ${Reset('everything')}
  appearance: none;
  width: 3rem;
  height: 3rem;
  border: 1px solid var(--color-line);
  margin: 0 0.25rem;
  border-radius: 50%;
  text-align: center;

  &:focus {
    box-shadow: none;
    outline: 0;
  }
`
