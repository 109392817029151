import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import Icon from '@/components/Icon'
import CartResetModal from '@/features/Checkout/components/Cart/CartResetModal'
import useCheckout from '@/features/Checkout/hooks/useCheckout'

import useLayout from '../useLayout'
import { NavBarLogo, NavBarTool, NavBarWrapper } from './NavBar.styles'

const NavBar = () => {
  const { theme, showBack, showClose } = useLayout()
  const { currentStep, setCurrentStep, reset } = useCheckout()
  const navigate = useNavigate()
  const [showResetModal, setShowResetModal] = useState(false)
  const handleBack = () => {
    if (currentStep === 'CONFIRMATION') setCurrentStep('PAYMENT')
    else if (currentStep === 'PAYMENT') setCurrentStep('CART')
    else navigate(-1)
  }
  const handleClose = () => {
    if (currentStep === 'CONFIRMATION') {
      reset()
      navigate('/')
    } else setShowResetModal(true)
  }
  const buttonBackTransition = {
    initial: { x: 5, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: -5, opacity: 0 },
    transition: { duration: 0.15 }
  }

  const buttonCloseTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.15 }
  }

  return (
    <>
      <NavBarWrapper>
        <NavBarTool>
          <AnimatePresence>
            {showBack && (
              <motion.button {...buttonBackTransition} onClick={handleBack}>
                <Icon name="arrow-left"></Icon>
              </motion.button>
            )}
          </AnimatePresence>
        </NavBarTool>
        <NavBarLogo inverted={theme === 'DARK'}>
          <img src={require('@/assets/img/logo-dark.png')} alt="" />
          <img src={require('@/assets/img/logo-light.png')} alt="" />
        </NavBarLogo>
        <NavBarTool>
          <AnimatePresence>
            {showClose && (
              <motion.button {...buttonCloseTransition} onClick={handleClose}>
                <Icon name="close"></Icon>
              </motion.button>
            )}
          </AnimatePresence>
        </NavBarTool>
      </NavBarWrapper>
      <AnimatePresence>{showResetModal && <CartResetModal onClose={() => setShowResetModal(false)} />}</AnimatePresence>
    </>
  )
}

export default NavBar
