import { useTranslation } from 'react-i18next'

import { CURRENCY_SIGN } from '@/lib/helpers'

import useCheckout from '../Checkout/hooks/useCheckout'
import { SummaryDetail, SummaryTable, SummaryTotal, SummaryWrapper } from './Summary.styles'
import SummaryItem from './SummaryItem'

const Summary = () => {
  const { cart, cartTotal } = useCheckout()
  const { t } = useTranslation()

  return (
    <SummaryWrapper>
      <SummaryTable>
        {cart.map(product => (
          <SummaryItem key={product.id} product={product} />
        ))}
      </SummaryTable>
      <SummaryDetail>
        <dt>{t('subtotal')}</dt>
        <dd>
          {CURRENCY_SIGN}
          {(cartTotal / 1.23).toFixed(2)}
        </dd>
      </SummaryDetail>
      <SummaryDetail>
        <dt>{t('tax')}</dt>
        <dd>
          {CURRENCY_SIGN}
          {(cartTotal - cartTotal / 1.23).toFixed(2)}
        </dd>
      </SummaryDetail>
      <SummaryTotal>
        <dt>{t('total')}</dt>
        <dd>
          {CURRENCY_SIGN}
          {cartTotal.toFixed(2)}
        </dd>
      </SummaryTotal>
    </SummaryWrapper>
  )
}

export default Summary
