import styled, { css } from 'styled-components'

import { Reset, TransitionPrimary } from '@/lib/mixins'

export const NavBarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: var(--size-nav-bar);
  padding: 0 0.5rem;
`

export const NavBarLogo = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 20px;
    max-width: 100%;
    object-fit: contain;
    display: block;
    ${TransitionPrimary('opacity')}

    &:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      transform: translate3d(-50%, -50%, 0);
      opacity: 0;
    }
  }

  ${props =>
    props.inverted &&
    css`
      img {
        &:nth-child(1) {
          opacity: 0;
        }

        &:nth-child(2) {
          opacity: 1;
        }
      }
    `};
`

export const NavBarTool = styled.div`
  flex: 0 0 25%;
  display: flex;

  button {
    ${Reset('button')}
    display: inline-flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;

    svg {
      height: 2rem;
      display: block;
      fill: currentColor;
      ${TransitionPrimary('fill')}
    }
  }

  &:last-child {
    justify-content: flex-end;
  }
`
