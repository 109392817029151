import { useEffect, useState } from 'react'
import Moment from 'react-moment'

import Icon from '@/components/Icon'

import { TopBarTime, TopBarTool, TopBarWrapper } from './TopBar.styles'

const TopBar = () => {
  const [batteryLevel, setBatteryLevel] = useState(0)
  const [networkType, setNetworkType] = useState('')
  const [networkStrength, setNetworkStrength] = useState(0)

  const getDeviceStatus = () => {
    if (window.BtDevice) {
      try {
        setBatteryLevel(window.BtDevice.getBatteryLevel())
        setNetworkType(window.BtDevice.networkType())
        setNetworkStrength(window.BtDevice.networkStrength())
      } catch (error) {
        console.error('[DEVICE] getDeviceStatus', error)
      }
    }
  }

  useEffect(() => {
    getDeviceStatus()

    setInterval(() => {
      getDeviceStatus()
    }, 10 * 1000)
  }, [])

  return (
    <TopBarWrapper>
      <TopBarTime>
        <Moment interval={1000} format="HH:mm" />
      </TopBarTime>
      <TopBarTool level={networkStrength}>
        <Icon name="wifi" />
        <span>{networkType}</span>
      </TopBarTool>
      <TopBarTool level={batteryLevel}>
        <Icon name="battery" />
      </TopBarTool>
    </TopBarWrapper>
  )
}

export default TopBar
