import { Trans, useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Modal from '@/components/Modal'

import useCheckout from '../../hooks/useCheckout'

const CartItemRemoveModal = props => {
  const { product, onClose } = props
  const { id, imageUrl, title } = product
  const { removeFromCart } = useCheckout()
  const { t } = useTranslation()

  return (
    <Modal variant="confirmation" onClose={onClose}>
      <img src={imageUrl} alt="" />
      <h3>
        <Trans i18nKey="removeFromCartQuestion" values={{ title }}>
          Are you sure to remove <strong>{title}</strong> from cart?
        </Trans>
      </h3>
      <Button variant="secondary" onClick={onClose}>
        {t('cancel')}
      </Button>
      <Button variant="red" onClick={() => removeFromCart({ ...product, all: true })}>
        {t('remove')}
      </Button>
    </Modal>
  )
}

export default CartItemRemoveModal
