import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import Modal from '@/components/Modal'

import useCheckout from '../../hooks/useCheckout'
import { CartModalImages } from './Cart.styles'

const CartResetModal = props => {
  const { onClose } = props
  const { cart, reset } = useCheckout()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleReset = () => {
    reset()
    navigate('/')
    onClose()
  }

  return (
    <Modal variant="confirmation" onClose={onClose}>
      <CartModalImages>
        {cart.map(o => (
          <img key={o.id} src={o.imageUrl} />
        ))}
      </CartModalImages>
      <h3>{t('closeSessionQuestion')}</h3>
      <Button variant="secondary" onClick={onClose}>
        {t('cancel')}
      </Button>
      <Button variant="red" onClick={() => handleReset()}>
        {t('close')}
      </Button>
    </Modal>
  )
}

export default CartResetModal
