import styled from 'styled-components'

export const ConfirmationWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ConfirmationContent = styled.div`
  text-align: center;
  max-width: 20rem;
  margin: auto;
  h1 {
    margin-bottom: 0.5rem;
  }

  p {
    font-family: var(--font-secondary);
    opacity: 0.5;
    margin-bottom: 0;
  }

  svg {
    width: 8rem;
    margin-bottom: 2rem;
  }
`

export const ConfirmationFooter = styled.div`
  margin-top: auto;
`
