import { useDispatch, useSelector } from 'react-redux'

import {
  addToCart,
  cartTotalSelector,
  removeFromCart,
  reset,
  setCurrentStep,
  setPaymentError,
  setPaymentMethod
} from '../store/checkoutSlice'

const useCheckout = () => {
  const state = useSelector(state => state.checkout)
  const dispatch = useDispatch()

  return {
    ...state,
    cartTotal: cartTotalSelector(state),
    setCurrentStep: payload => dispatch(setCurrentStep(payload)),
    reset: payload => dispatch(reset(payload)),
    setPaymentMethod: payload => dispatch(setPaymentMethod(payload)),
    setPaymentError: payload => dispatch(setPaymentError(payload)),
    addToCart: payload => dispatch(addToCart(payload)),
    removeFromCart: payload => dispatch(removeFromCart(payload))
  }
}

export default useCheckout
