import { motion } from 'framer-motion'

import { StepWrapper } from './Step.styles'

const Step = ({ children }) => {
  return (
    <StepWrapper
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2
      }}
    >
      {children}
    </StepWrapper>
  )
}

export default Step
