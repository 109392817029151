import styled, { css } from 'styled-components'

import { Reset, TransitionPrimary } from '@/lib/mixins'

export const ButtonWrapper = styled.button`
  ${Reset('button')}
  ${TransitionPrimary()}
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.3em;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: 1px solid transparent;
  font-weight: 600;
  height: 3.75rem;
  padding: 0 1.75rem;
  font-size: 0.8rem;
  line-height: 1;

  ${props =>
    props.variant === 'red' &&
    css`
      background-color: var(--color-red);
      color: var(--color-inverted);
    `};

  ${props =>
    props.variant === 'primary' &&
    css`
      background-color: var(--color-global);
      color: var(--color-inverted);
    `};

  ${props =>
    props.variant === 'secondary' &&
    css`
      border: 1px solid var(--color-line);
      color: var(--color-global);
    `};

  ${props =>
    props.block &&
    css`
      width: 100%;
      display: flex;

      & + ${ButtonWrapper} {
        margin-top: 0.5rem;
      }
    `};

  ${props =>
    props.rounded &&
    css`
      background: var(--color-body);
      border: 1px solid var(--color-line);
      border-radius: 2rem;
      height: 2rem;
      font-size: 0.65rem;
      padding: 0 0.75rem;
      letter-spacing: 0.1em;
      color: var(--color-global);
    `};
`

export const ButtonBadge = styled.span`
  display: inline-block;
  padding: 0.25em 0.5em;
  letter-spacing: 0;
  font-size: 0.9em;
  background-color: var(--color-inverted);
  border-radius: 1em;
  color: var(--color-global);
  margin-left: 0.5rem;
`
