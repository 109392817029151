import { timingFunctions } from 'polished'
import { css } from 'styled-components'

import media from './media'
import theme from './theme'

export const TransitionPrimary = (property = 'all', delay = '0s') => css`
  transition-property: ${property};
  transition-duration: 0.2s;
  transition-delay: ${delay};
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  will-change: ${property};
`

export const TransitionSecondary = (property = 'all', delay = '0s') => css`
  transition-property: ${property};
  transition-duration: 0.65s;
  transition-delay: ${delay};
  transition-timing-function: ${timingFunctions('easeOutExpo')};
  will-change: ${property};
`

export const Cover = (offset = '0') => css`
  position: absolute;
  top: ${offset};
  left: ${offset};
  right: ${offset};
  bottom: ${offset};
  content: ' ';
`

export const ImgCover = (offset = '0') => css`
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const LayoutSpacing = (type = 'default') => css`
   {
    ${media('>md')} {
      padding-top: ${theme.spacing[type].desktop};
      padding-bottom: ${theme.spacing[type].desktop};
    }
    ${media('<=md')} {
      padding-top: ${theme.spacing[type].mobile};
      padding-bottom: ${theme.spacing[type].mobile};
    }
  }
`

export const Font = (size = '1rem', weight = 'regular', name = 'primary') => css`
  font-weight: ${theme.font[name][weight]};
  font-size: ${size};
  font-family: ${theme.font[name].family};
`
export const Reset = ($type = 'all') => {
  if ($type === 'link') {
    return css`
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus {
        color: inherit;
      }
    `
  }
  if ($type === 'spacing') {
    return css`
      margin: 0;
      padding: 0;
    `
  }
  if ($type === 'list') {
    return css`
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;
      }
    `
  }
  if ($type === 'button') {
    return css`
      color: inherit;
      padding: 0;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      outline: none;
    `
  }
  if ($type === 'all') {
    return css`
      color: inherit;
      background-color: transparent;
      border: 0;
      float: none;
      height: auto;
      list-style: none;
      margin: 0;
      padding: 0;
      position: static;
      width: auto;
    `
  }
}

export const VerticalPadding = (type = 'consist') => css`
  ${type !== 'large' &&
  css`
    @media ${props => props.theme.mq.min.lg} {
      padding-top: ${props => props.theme.spacing.default.desktop};
      padding-bottom: ${props => props.theme.spacing.default.desktop};
    }
  `}
  ${type === 'consist' &&
  css`
    @media ${props => props.theme.mq.max.lg} {
      padding-top: ${props => props.theme.spacing.default.mobile};
      padding-bottom: ${props => props.theme.spacing.default.mobile};
    }
  `}
  ${type === 'mobile-small' &&
  css`
    @media ${props => props.theme.mq.max.lg} {
      padding-top: ${props => props.theme.spacing.small.mobile};
      padding-bottom: ${props => props.theme.spacing.small.mobile};
    }
  `}
  ${type === 'large' &&
  css`
    @media ${props => props.theme.mq.min.lg} {
      padding-top: ${props => props.theme.spacing.large.desktop};
      padding-bottom: ${props => props.theme.spacing.large.desktop};
    }
    @media ${props => props.theme.mq.max.lg} {
      padding-top: ${props => props.theme.spacing.large.mobile};
      padding-bottom: ${props => props.theme.spacing.large.mobile};
    }
  `}
`

export const InputField = () => css`
  border: 1px solid ${props => (props.error ? props.theme.color.red : props.theme.color.line)};
  display: block;
  width: 100%;
  font-size: 1rem;
  appearance: none !important;
  box-shadow: none !important;
  height: ${props => props.theme.control.md};
  padding: 0 1rem;
  color: ${props => props.theme.color.global};
  ${TransitionPrimary()}
  border-radius: ${props => props.theme.radius.md};
  background-color: ${props => props.theme.color.body};

  ${props =>
    props.size === 'large' &&
    css`
      height: ${props => props.theme.control.lg};
    `};

  &::-internal-autofill-selected {
    background-color: transparent !important;
  }

  &::placeholder {
    color: ${props => props.theme.color.muted};
  }

  ${props =>
    props.disabled &&
    css`
      background-color: ${props => props.theme.color.light};
      cursor: not-allowed;
    `}

  ${props =>
    !props.disabled &&
    css`
      &:focus,
      &:focus-visible {
        outline: 0;
        border: 1px solid ${props => (props.error ? props.theme.color.red : props.theme.color.primary)};
      }
    `}
`

export const LinkUnderlineOnHover = (type = 'primary') => css`
  position: relative;
  white-space: nowrap;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: currentColor;
    opacity: 0;
    transform: scale(0.9) translateY(3px);
    visibility: hidden;
    ${TransitionPrimary('transform, opacity, visibility')}
    content: ' ';
  }

  &:hover {
    &:after {
      opacity: 1;
      transform: scale(1) translateY(0);
      visibility: visible;
    }
  }
`

export const LinkUnderline = (type = 'primary') => css`
  ${Reset('link')}
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: currentColor;
    ${TransitionPrimary('transform, opacity, visibility')}
    content: ' ';
  }

  &:hover {
    &:after {
      transform: scale(0);
    }
  }
`

export const HasSvgIcon = (size = '1em') => css`
  svg {
    height: ${size};
    width: ${size};
    display: block;
  }
`

export const LastMb0 = () => css`
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`
