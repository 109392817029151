import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import View from '@/layout/View'

import Summary from '../Summary'
import Cart from './components/Cart'
import Confirmation from './components/Confirmation'
import Payment from './components/Payment'
import useCheckout from './hooks/useCheckout'

const Checkout = () => {
  const { currentStep, steps } = useCheckout()
  const { t } = useTranslation()

  return (
    <View
      theme="DARK"
      steps={steps}
      currentStep={currentStep}
      showClose
      collapsibleComponent={currentStep === 'PAYMENT' && <Summary />}
      collapsibleTitle={t('orderSummary')}
    >
      <AnimatePresence mode="wait">
        {currentStep === 'CART' && <Cart key="CART" />}
        {currentStep === 'PAYMENT' && <Payment key="PAYMENT" />}
        {currentStep === 'CONFIRMATION' && <Confirmation key="CONFIRMATION" />}
      </AnimatePresence>
    </View>
  )
}

export default Checkout
