import { useTranslation } from 'react-i18next'

import Icon from '@/components/Icon'

import { ScannerIndicatorIcon, ScannerIndicatorWrapper } from './Scanner.styles'

const ScannerIndicator = ({ size = 'default', text }) => {
  const { t } = useTranslation()

  return (
    <ScannerIndicatorWrapper size={size}>
      <ScannerIndicatorIcon onClick={openQrCodeCamera}>
        <Icon name="barcode" />
      </ScannerIndicatorIcon>
      <h5>{text || t('scanBarCode')}</h5>
    </ScannerIndicatorWrapper>
  )
}

const openQrCodeCamera = () => {
  if (window.BtDevice) {
    try {
      window.BtDevice.startCameraQrScanner()
    } catch (error) {
      console.error('[DEVICE] openQrCodeCamera', error)
    }
  } else {
    console.log(`[DEVICE] openQrCodeCamera - btDevice not available`)
  }
}

export default ScannerIndicator
