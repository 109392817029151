import styled, { css } from 'styled-components'

import { Cover, Reset, TransitionPrimary } from '@/lib/mixins'

export const PaymentWrapper = styled.div`
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PaymentTotal = styled.div`
  dl {
    margin-bottom: 1.75rem;
  }
  dt,
  dd {
    text-align: center;
    display: block;
  }

  dd {
    font-size: 2.25rem;
  }
`

export const PaymentFooter = styled.div`
  margin-top: auto;
  padding-top: 1.25rem;
`

export const PaymentOptions = styled.div``

export const PaymentOptionIcon = styled.div`
  margin-right: 1.5rem;

  svg {
    width: 3rem;
    height: 3rem;
    display: block;
    fill: currentColor;
  }
`

export const PaymentOptionContent = styled.div`
  h2 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`

export const PaymentOptionWrapper = styled.button`
  ${Reset('button')}
  width: 100%;
  position: relative;
  display: flex;
  height: 6.5rem;
  align-items: center;
  border: 1px solid var(--color-line);
  padding: 0 2rem;

  &:after {
    ${Cover('-1px')}
    border: 7px solid currentColor;
    ${TransitionPrimary('transform, opacity, visibility')}
    visibility: hidden;
    opacity: 0;
    transform: scale(1.05);
  }

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  ${props =>
    props.active &&
    css`
      &:after {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    `};
`
