import { breakpoints } from './theme'

const signRegex = /^[<=>]+/

const convertToQuery = param => {
  const [sign, screenSize] = parseParam(param)
  const size = calculateSize(sign, screenSize)
  const unit = typeof size === 'number' ? 'px' : ''
  const widthCondition = sign.includes('>') ? 'min-width' : 'max-width'
  return `(${widthCondition}: ${size}${unit})`
}

const parseParam = param => {
  const sign = param.match(signRegex) ? param.match(signRegex)[0].trim() : ''
  const screenSize = param.replace(signRegex, '').trim()
  return [sign, screenSize]
}

const calculateSize = (sign, screenSize) => {
  if (sign.includes('=')) {
    return breakpoints[screenSize]
  }

  if (sign.includes('>')) {
    return breakpoints[screenSize] + 1
  }

  return breakpoints[screenSize] - 1
}

const media = (query, betweenQuery) => {
  const queries = betweenQuery ? [query, betweenQuery] : [query]
  return `@media ${queries.map(convertToQuery).join(' and ')}`
}

export default media
