import styled from 'styled-components'

import { Cover } from '@/lib/mixins'

export const RelatedWrapper = styled.div`
  width: 100%;
  padding-bottom: 4rem;
`
export const RelatedHeader = styled.div`
  text-align: center;
  margin-top: -1.25rem;
  img {
    height: 5rem;
  }
  h4 {
    margin: 1.5rem 0 1.75rem;
  }
`

export const RelatedBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const RelatedItem = styled.div`
  flex: 0 0 50%;
  padding: 0 0.75rem;

  &:nth-child(2n) {
    margin-top: 3rem;
  }

  img {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  h6 {
    margin-bottom: 0.25rem;
  }

  h2 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 0;
  }
`

export const RelatedScanner = styled.div`
  position: fixed;
  z-index: 3;
  left: 0;
  bottom: 0;
  right: 0;

  &:before {
    ${Cover()}
    top: -3rem;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0));
  }
`
