import { useContext } from 'react'

import { LayoutContext } from './LayoutContext'

const useLayout = () => {
  const { state, dispatch } = useContext(LayoutContext)

  return {
    ...state,
    setTheme: payload => dispatch({ type: 'SET_THEME', payload }),
    setShowBack: payload => dispatch({ type: 'SET_SHOW_BACK', payload }),
    setShowClose: payload => dispatch({ type: 'SET_SHOW_CLOSE', payload })
  }
}

export default useLayout
