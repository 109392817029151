import SVG from 'react-inlinesvg'

const Icon = props => {
  const { name } = props
  try {
    const icon = require(`@/assets/icons/${name}.svg`)

    return <SVG src={icon} preProcessor={code => code.replace(/fill=".*?"/g, '')}></SVG>
  } catch {
    console.error(`<Icon> Missing "${name}" icon`)
    return <div></div>
  }
}

export default Icon
